@use 'design-system/theme/palette' as *;
@use 'design-system/typography/fonts' as *;

html {
  font-size: 62.5%;
}

body {
  font-size: 1.4rem;
  font-weight: 400;
  line-height: 1.7rem;
  background-color: $ink-02;
  color: $ink-85;
  font-family: $app-font-family;
}

html,
body {
  margin: 0;
  height: 100%;
}
