// this file should be included once by `angular.json` on a per cintra-spa basis

$headings-margin-bottom: 0.83em;
@use 'cintra-bootstrap' as *;
@use '../../app/app-layout/css-vars.scss' as *;
@use 'design-system/theme/cintra-material' as *;
@use 'document' as *;
@use 'highlight-js' as *;
@use 'ag-grid' as *;
@use './design-system/mixins/cintra-scrollbar';
@use 'animate.css';

@include cintra-scrollbar.cintraScrollbar();

.cursor-pointer {
  cursor: pointer;
}

::placeholder {
  font-style: italic;
  font-size: 1.3rem;
}

button {
  outline: 0;
  border: 0;
  &:focus {
    outline: 0;
    border: 0;
  }
}

td,
th {
  vertical-align: middle;
}

.clickable {
  cursor: pointer;
}

.hidden {
  display: none;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus {
  -webkit-background-clip: text !important;
  font-size: 1.3rem !important;
}

mat-tooltip-component .mat-mdc-tooltip .mdc-tooltip__surface {
  background-color: inherit !important;
  font-size: 1.4rem !important;
}

.beamer-button .beamer_icon.active {
  left: 6px;
  top: -4px;
  position: relative;
}
