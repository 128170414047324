@use '../theme/palette' as *;

@mixin cintraScrollbar {
  $background-color: $ink-02;
  $scroller-color: $ink-10;

  /* Works on Firefox */
  * {
    scrollbar-width: thin;
    scrollbar-color: $scroller-color $background-color;
  }

  /* Works on Chrome, Edge, and Safari */
  *::-webkit-scrollbar {
    width: 12px;
  }

  *::-webkit-scrollbar-track {
    background: $background-color;
    border: 1px solid white;
  }

  *::-webkit-scrollbar-thumb {
    background-color: $scroller-color;
    border-radius: 20px;
  }
}
