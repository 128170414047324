@use '@ag-grid-community/styles' as ag;
@use './design-system/theme/palette' as *;
@use 'design-system/typography/fonts' as *;

@include ag.grid-styles(
  (
    theme: alpine,

    --ag-icon-font-family: agGridMaterial,
    --ag-font-family: $app-font-family,
    --ag-font-size: 1.4rem,

    --ag-header-height: 56px,
    --ag-grid-size: 6px,
    --ag-row-height: 56px,
    --ag-cell-horizontal-padding: 8px,
    --ag-toggle-button-height: 24px,
    --ag-toggle-button-width: 38px,
    --ag-toggle-button-border-width: 3px,

    alpine-active-color: $primary-action,
    --ag-row-hover-color: $ink-02,
    --ag-data-color: $ink-85,
    --ag-header-foreground-color: $ink-85,
    --ag-background-color: #fff,
    --ag-header-background-color: #fff,
    --ag-odd-row-background-color: #fff,
    --ag-control-panel-background-color: #fff,
    --ag-row-border-color: $ink-10,
    --ag-toggle-button-off-background-color: $system-disabled,
    --ag-toggle-button-off-border-color: $system-disabled,
    --ag-toggle-button-on-background-color: $system-green,
    --ag-toggle-button-on-border-color: $system-green
  )
);

.ag-theme-alpine .ag-row,
.ag-theme-alpine-dark .ag-row {
  font-size: var(--ag-font-size);
}

.ag-column-panel {
  font-size: 1.3rem;
}

.ag-theme-alpine {
  input[class^='ag-'][type='text'] {
    padding: 8px;
  }
}

.ag-status-bar {
  padding-left: 0;
  padding-right: 0;
}

.ag-cell-wrap-text {
  word-break: break-word;
}
