/**
  must be kept in sync with CssBreakpointsService
 */

// minimum work-area width
$content-min: 320px;

// e.g. mobile portrait
$screen-mobile-max: 431px;

// e.g. mobile portrait
$screen-sm-max: 649px;

// e.g. mobile landscape, tablet portrait
$screen-md-min: 650px;
$screen-md-max: 1279px;

// e.g. tablet landscape, desktop
$screen-lg-min: 1280px;
$screen-lg-max: 1899px;

// maximum work-area width
$screen-xlg-min: 1900px;
