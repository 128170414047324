@use 'design-system/theme/palette' as *;
@use 'design-system/layout/breakpoints';
@use 'bootstrap/dist/css/bootstrap-reboot';
@use 'bootstrap/dist/css/bootstrap-grid';
@use 'bootstrap/dist/css/bootstrap-utilities';

// ETN-189 build boostrap grid from scss and reduce breakpoints in line with design system
// https://getbootstrap.com/docs/5.0/layout/grid/#grid-tiers
$container-max-widths: (
  sm: breakpoints.$screen-md-min,
  md: breakpoints.$screen-md-min,
  lg: breakpoints.$screen-lg-min,
  xl: 1140px,
  xxl: 1140px
);

a {
  text-decoration: none;
}
