pre code.hljs {
  display: block;
  overflow-x: auto;
  padding: 1em
}
code.hljs {
  padding: 3px 5px
}
/**
 * Shades of Purple Theme — for Highlightjs.
 *
 * @author (c) Ahmad Awais <https://twitter.com/mrahmadawais/>
 * @link GitHub Repo → https://github.com/ahmadawais/Shades-of-Purple-HighlightJS
 * @version 1.5.0
 */
.hljs {
  background: #2d2b57;
  color: #e3dfff;
  font-weight: normal
}
.hljs-subst {
  color: #e3dfff
}
.hljs-title {
  color: #fad000;
  font-weight: normal
}
.hljs-name {
  color: #a1feff
}
.hljs-tag {
  color: #ffffff
}
.hljs-attr {
  color: #f8d000;
  font-style: italic
}
.hljs-built_in,
.hljs-selector-tag,
.hljs-section {
  color: #fb9e00
}
.hljs-keyword {
  color: #fb9e00
}
.hljs-string,
.hljs-attribute,
.hljs-symbol,
.hljs-bullet,
.hljs-addition,
.hljs-code,
.hljs-regexp,
.hljs-selector-class,
.hljs-selector-attr,
.hljs-selector-pseudo,
.hljs-template-tag,
.hljs-quote,
.hljs-deletion {
  color: #4cd213
}
.hljs-meta,
.hljs-meta .hljs-string {
  color: #fb9e00
}
.hljs-comment {
  color: #ac65ff
}
.hljs-keyword,
.hljs-selector-tag,
.hljs-literal,
.hljs-name,
.hljs-strong {
  font-weight: normal
}
.hljs-literal,
.hljs-number {
  color: #fa658d
}
.hljs-emphasis {
  font-style: italic
}
.hljs-strong {
  font-weight: bold
}