@use 'node_modules/highlight.js/styles/shades-of-purple.css' as *;

pre {
  display: flex;
  margin-top: 0;
  margin-bottom: 0;
  word-wrap: break-word;

  code {
    flex: 1;
    line-height: 1.5em;
    font-size: 13px;
    min-height: 100%;
    padding: 1em 1.2em;

    &.hljs-line-numbers {
      padding: 0;
    }
  }
}

/** Line numbers styles */
.hljs {
  padding: 10px;
  border: 1px solid gray;
  border-radius: 4px;
  transition: border ease 1s;
}

.hljs-ln {
  padding: 8px 0;

  tr {
    &:first-child td {
      padding-top: 8px !important;
    }

    &:last-child td {
      padding-bottom: 8px !important;
    }
  }
}

/* for block of numbers */
td.hljs-ln-numbers {
  user-select: none;
  text-align: center;
  border-right: 1px solid gray;
  vertical-align: top;
  padding-right: 8px !important;
  padding-left: 8px !important;
}

/* for block of code */
td.hljs-ln-code {
  padding-left: 8px !important;
}
