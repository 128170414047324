@use './design-system/layout/breakpoints';

// included once globally by angular.json
:root {
  --logo-row-height: 5.6rem;
  --top-nav-tabs-height: 4rem;
  --top-nav-height: calc(var(--logo-row-height) + var(--top-nav-tabs-height));
  --product-nav-height: 5.6rem;
  --header-height: calc(var(--top-nav-height) + var(--product-nav-height));
  --content-height: calc(100vh - var(--header-height));
  --right-sidebar-width: 38rem;
  --h4-height: 4.8rem;
  --content-padding: 3.2rem;
  --modal-header-height: 7.2rem;
}

@media (min-width: breakpoints.$screen-lg-min) {
  :root {
    --top-nav-height: var(--logo-row-height);
  }
}
